body {
  background-color: black;
  font-size: 16px;
}

header {
  display: grid;
  place-items: center center;
  height: 220px;
  background-color: black;
}

.logo {
  width: 300px;
}

h1 {
  font-size: 1.8rem;
  color: #e5b13a;
  margin-top: -25px;
}

.search {
  padding: 15px;
  background-color: #e5b13a;
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
}

label {
  padding-right: 15px;
  padding-left: 10px;
}

button {
  width: 100px;
  height: 40px;
  margin-left: 25px;
  border: none;
  background-color: black;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #e5b13a;
}

.filterLabel {
  margin-left: 50px;
}

.App {
  display: grid;
  place-items: center center;
}
.loading {
  margin-top: 150px;
}

.error-page {
  margin-top: 200px;
}

.noResults {
  margin-top: 50px;
  font-size: 24px;
  font-weight: 700;
  color: #e5b13a;
}
